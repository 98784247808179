import { navigate } from 'gatsby'

const HomepageRedirect = () => {
  if (typeof window !== 'undefined') {
    navigate('/')
  }

  return null
}

export default HomepageRedirect
